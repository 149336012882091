import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/aXPHZNPmMQM"
    bibleGroupSrc="https://pdfhost.io/v/mDtNt0M62_Bible_Group_Homework_314pdf.pdf"
    bibleGroupText="Bible Group Homework"
  >
    <SEO title="For Significance - Searching..." />
  </Layout>
)

export default SermonPost
